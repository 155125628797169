import { Grid } from "@mui/material";
export const ChooseUs = () => {
  return (
    <section>
       <div id="rt_welcome" style={{ paddingTop: "0px" }}>
      <div className="container">
        <div className="choose-us-section">
  
          <div className="banner_caption_text">
                <h1 className="text-center" style={{ fontWeight: "600" }}>
                Why Choose Us
                </h1>
              </div>
          <p className="heading_txt text-center">
            Our integrated platform simplifies the complexities of blockchain,
            allowing you to focus on creating and deploying applications without
            the hassle of managing infrastructure.
          </p>
        </div>
        <br /><br/>
      </div>
      <div className="choose-us-container">
        <Grid container spacing={2} >
          <Grid item xs={12} md={4}>    <div className="choose-us-box choose-us-rt-box">
            <img
              src="/images/cu1.png"
              alt=""
              className="icon-briefcase"
            />
            <h5>Simplifying Implementation</h5>
            <p>
              Offering pre-built solutions, tools, and support services
              tailored to the specific needs of different industries,
              thereby making blockchain adoption convenient.
            </p>
          </div></Grid>
          <Grid item xs={12} md={4} >     <div className="choose-us-box choose-us-rt-box">
            <img
              src="/images/cu2.png"
              alt=""
              className="icon-briefcase"
            />
            <h5>Reducing Costs</h5>
            <p>
              Ready-made solutions and support services to reduce the
              costs associated with blockchain implementation, making it
              more feasible for businesses of all sizes.
            </p>
     
          </div></Grid>
          <Grid item xs={12} md={4} >     <div className="choose-us-box choose-us-rt-box">
            <img
              src="/images/cu3.png"
              alt=""
              className="icon-briefcase"
            />
            <h5>Improving Efficiency</h5>
            <p>
              Doras can help businesses integrate blockchain solutions
              seamlessly into their existing workflows, leading to
              improved efficiency, transparency, and traceability.
        
            </p>
          </div></Grid>
        </Grid>
        {/* <div className="col-sm-12 col-md-6 col-lg-3 choose-us-box">
                  <div className="rt_service_box choose-us-rt-box">
                    <img
                      src="/images/cu1.png"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h5>Simplifying Implementation</h5>
                    <p>
                      Offering pre-built solutions, tools, and support services
                      tailored to the specific needs of different industries,
                      thereby making blockchain adoption convenient.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 choose-us-box" >
                  <div className="rt_service_box choose-us-rt-box">
                    <img
                      src="/images/cu2.png"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h5>Reducing Costs</h5>
                    <p>
                      Ready-made solutions and support services to reduce the
                      costs associated with blockchain implementation, making it
                      more feasible for businesses of all sizes.
                    </p>
                    <br />
                    <br />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 choose-us-box">
                  <div className="rt_service_box choose-us-rt-box">
                    <img
                      src="/images/cu3.png"
                      alt=""
                      className="icon-briefcase"
                    />
                    <h5>Improving Efficiency</h5>
                    <p>
                      Doras can help businesses integrate blockchain solutions
                      seamlessly into their existing workflows, leading to
                      improved efficiency, transparency, and traceability.
                      <br />
                      <br />
                    </p>
                  </div>
                </div> */}
      </div>
      </div>
    </section>
  );
};
