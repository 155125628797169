import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import "./css/networkDetails.css";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  statement: Yup.string().required("Statement is required"),
});

const initialValues = {
  name: "",
  statement: "",
};

const NetworkDetails = ({ setTab }) => {
  const handleSubmit = (values) => {
    // Handle form submission here
    // console.log("Form submitted:", values);
    setTab("SetUpNode");
  };

  return (
    <div className="network_details_main">
      <h3>Enter Network Details</h3>
      <div className="network_details_main_form">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="network_details_label_form_box">
                  <label>Name of this network</label>
                  <Field
                    type="text"
                    name="name"
                    placeholder="Example Network"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <div className="network_details_label_form_box">
                  <label>{"Network mission statement"}</label>
                  <Field
                    as="textarea"
                    name="statement"
                    placeholder="Network mission statement"
                    style={{ height: 112 }}
                  />
                  <ErrorMessage
                    name="statement"
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
              </Grid>
              <div className="flex-center-box">
                <button className="network_details_button">Cancel</button>

                <button type="submit" className="network_details_button">
                  Next
                </button>
              </div>
            </Grid>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default NetworkDetails;
