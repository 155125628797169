import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import { post } from "../../service/apiService";
import { config } from "../../config";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header copy/NewHeader";
import { Footer } from "../../components/footer/footer";
import Header2 from "../../components/header/header";
import "./index.css";
const { blockChainService } = config.authEndPoints;
const { dockerRunFile } = config.authEndPoints;
const inputData = [
  {
    label: "Network Name:",
    name: "networkName",
    placeholder: "Please enter your network name",
  },
  {
    label: "Network Symbol:",
    name: "networkSymbol",
    placeholder: "Please enter your network symbol",
  },
  {
    label: "Wallet Address",
    name: "genesis_alloc_address",
    placeholder: "Wallet Address",
  },
  {
    label: "Network ID:",
    name: "sh_network_id",
    placeholder: "Network Id",
  },
  {
    label: "Native Currency Supply:",
    name: "genesis_alloc_balance",
    placeholder: "Native Currency Supply",
  },
  {
    label: "HTTP Port:",
    name: "sh_http_port",
    placeholder: "HTTP Port",
  },
  {
    label: "WS Port:",
    name: "sh_ws_port",
    placeholder: "WS Port",
  },
  {
    label: "Authrpc Port:",
    name: "dockerfile_expose_port",
    placeholder: "Authrpc Port",
  },
];
const validationSchema = Yup.object().shape({
  networkName: Yup.string().required("This is required field"),
  networkSymbol: Yup.string().required("This is required field"),
  genesis_alloc_address: Yup.string().required("Wallet Address is required"),
  sh_network_id: Yup.string().required("Network ID is required"),
  genesis_alloc_balance: Yup.string().required(
    "Native Currency Supply is required"
  ),
  sh_http_port: Yup.string().required("HTTP Port is required"),
  sh_ws_port: Yup.string().required("WS Port is required"),
  dockerfile_expose_port: Yup.string().required("Authrpc Port is required"),
  blockchain: Yup.string().required("Blockchain is required"),
  consensus: Yup.string().required("Consensus is required"),
  additionalDetails: Yup.string(),
});

const initialValues = {
  networkName: "",
  networkSymbol: "",
  genesis_alloc_address: "",
  sh_network_id: "",
  genesis_alloc_balance: "",
  sh_http_port: "",
  sh_ws_port: "",
  dockerfile_expose_port: "",
  blockchain: "",
  consensus: "",
  additionalDetails: "",
};

const Homepage = () => {
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    const data = {
      networkName:values.networkName,
      networkSymbol:values.networkSymbol,
      genesis_chainId: values.sh_network_id,
      genesis_alloc_address: values.genesis_alloc_address,
      sh_network_id: values.sh_network_id,
      genesis_alloc_balance: values.genesis_alloc_balance,
      sh_http_port: values.sh_http_port,
      sh_ws_port: values.sh_ws_port,
      dockerfile_expose_port: values.dockerfile_expose_port,
    };
    try {
      const response = await post(blockChainService, data, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      if (response?.success) {
        const response = await post(
          dockerRunFile,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            },
          }
        );
        console.log(response , "response000")
        navigate("/node-deployed");
        toast.success("Your node deployed successfully");
      } else {
        toast.error(response?.message);
      }
    } catch (error) {}
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <Header2 />
      <div className="blockchain_development_main">
        <h3>Launch Your GETH Client</h3>
        <div className="blockchain_development_main_form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <Grid container spacing={2}>
                {inputData.map((data, i) => (
                  <Grid item xs={12} sm={6} key={i}>
                    <div className="blockchain_development_label_form_box">
                      <label>{data.label}</label>
                      <Field
                        type="text"
                        name={data.name}
                        placeholder={data.placeholder}
                      />
                      <ErrorMessage
                        name={data.name}
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                  </Grid>
                ))}

                <Grid item xs={12} sm={6}>
                  <div className="blockchain_development_label_form_box">
                    <label>{"Blockchain:"}</label>
                    <Field as="select" name="blockchain">
                      <option value="">Select Option</option>
                      <option value="Geth">Geth</option>
                      <option value="BSC">BSC</option>
                    </Field>
                    <ErrorMessage
                      name="blockchain"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className="blockchain_development_label_form_box">
                    <label>{"Consensus:"}</label>
                    <Field as="select" name="consensus">
                      <option value="">Select Option</option>
                      <option value="POW">POW</option>
                      <option value="POA">POA</option>
                    </Field>
                    <ErrorMessage
                      name="consensus"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="blockchain_development_label_form_box">
                    <label>{"Additional Details:"}</label>
                    <Field
                      as="textarea"
                      name="additionalDetails"
                      style={{ height: 112 }}
                    />
                    <ErrorMessage
                      name="additionalDetails"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: 40 }}
                >
                  <button
                    type="submit"
                    className="blockchain_development_button"
                  >
                    Submit
                  </button>
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </div>
      </div>
      {/* <Footer/> */}
    </div>
  );
};

export default Homepage;
