// import Link from "next/link";
import { Link } from 'react-router-dom';
import './Header.css';

import React, { useEffect, useReducer, useRef } from "react";
const initialState = {
  activeMenu: "",
  activeSubMenu: "",
  menuOpen: false,
  modalOpen: false,
};
function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_MENU":
      return {
        activeMenu: state.activeMenu === action.menu ? "" : action.menu,
        activeSubMenu: ""
      };
    case "TOGGLE_SUB_MENU":
      return {
        activeMenu: state.activeMenu,
        activeSubMenu: state.activeSubMenu === action.subMenu ? "" : action.subMenu
      };
      case "TOGGLE_MODAL":
      return { ...state, modalOpen: !state.modalOpen };
    case "setScrollY":
      return { ...state, scrollY: action.payload };
    default:
      return { ...state };
  }
}
function Header() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const headerRef = useRef(null);

  const handleScroll = () => {
    const { scrollY } = window;
    dispatch({ type: "setScrollY", payload: scrollY });
  };
 
  const toggleMenu = (menu) => {
    dispatch({ type: "TOGGLE_MENU", menu });
  };

  const toggleSubMenu = (subMenu) => {
    dispatch({ type: "TOGGLE_SUB_MENU", subMenu });
  };
  function toggleModal() {
    dispatch({ type: "TOGGLE_MODAL" });
  }
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const mobileBtn = document.querySelector(".mobile-menu-btn");

    const nav = document.querySelector(".main-nav");
    const menuClose = document.querySelector(".menu-close-btn");
    mobileBtn.addEventListener("click", () => {
      nav.classList.add("show-menu");
    });
    menuClose.addEventListener("click", () => {
      nav.classList.remove("show-menu");
    });
  });
  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggle2"
        aria-hidden="true"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.32809515636!2d90.3665091154322!3d23.806929392530332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c0d6f6b8c2ff%3A0x3b138861ee9c8c30!2sMirpur%2010%20Roundabout%2C%20Dhaka%201216!5e0!3m2!1sen!2sbd!4v1671510521035!5m2!1sen!2sbd"
              //  src={"/image"}
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`mobile-search ${state.modalOpen ? "slide" : ""}`}>
        <div className="container">
          <div className="row d-flex justify-content-center gy-4">
            <div className="col-md-11">
              <label>What are you looking for?</label>
              <input
                type="text"
                placeholder="Search Products, Category, Services"
              />
            </div>
            <div className="col-1 d-flex justify-content-end align-items-center gap-2">
              <div className="search-cross-btn style-two">
                <i className="bi bi-search" />
              </div>
              <div className="search-cross-btn style-two" onClick={toggleModal}>
                <i className="bi bi-x-lg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =============== search-area end  =============== */}
      {/* ========== header============= */}
      <header
        ref={headerRef}
        className={
          state.scrollY > 120
            ? "header-area style-4 sticky"
            : "header-area style-4 "
        }
      >
        <div className="container-fluid d-flex justify-content-between align-items-center px-0">
          <div className="header-logo">
            <Link legacyBehavior href="/">
              <a>
                <img
                  alt="image"
                  className="img-fluid"
                  src="/images/logo.png"
                />
              </a>
            </Link>
          </div>
          <div className="main-nav">
            <div className="mobile-logo-area d-lg-none d-flex justify-content-between align-items-center">
              <div className="mobile-logo-wrap">
                <Link legacyBehavior href="/">
                  <a>
                    <img
                      alt="image"
                      className="img-fluid"
                      src="/images/logo.png"
                    />
                  </a>
                </Link>
              </div>
              <div className="menu-close-btn">
                <span>X</span>
              </div>
            </div>
            <ul className="menu-list">
              <li className="menu-item-has-children">
                <div style={{display:"ruby"}}>
                <Link legacyBehavior to="/">
                  <a className="drop-down">HOME</a>
                </Link>

                {/* <i
                  className="bi bi-chevron-down dropdown-icon"
                  onClick={() => toggleMenu("home-one")}
                /> */}
                {/* <img src={"/images/down-arrow.png"} style={{width:"20px",height:"20px"}} onClick={() => toggleMenu("home-one")}  /> */}
                 </div>

                 
                {/*} <ul
                  className={
                    state.activeMenu === "home-one"
                      ? "sub-menu d-block"
                      : "sub-menu"
                  }
                >
                 
               
                </ul> */}
              </li>
              <li className="menu-item-has-children">
              <div style={{display:"ruby"}}>
                <a href="#" className="drop-down">
                  BAAS
                </a>
                {/* <i
                  className="bi bi-chevron-down dropdown-icon"
                  onClick={() => toggleMenu("pages")}
                /> */}
                <img  src={"/images/down-arrow.png"}  style={{width:"20px",height:"20px"}}  onClick={() => toggleMenu("pages")}/>
                </div><ul className={state.activeMenu === "pages" ? "sub-menu d-block" : "sub-menu"}>
                  <li>
                    <Link legacyBehavior to="/">
                    <div   style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/BAAS (1).png' style={{width:"35px",height:"35px"}} />
                      <a>Arbitrum</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior to="/">
                    <div   style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/BAAS (2).png' style={{width:"35px",height:"35px"}} />

                      <a>Corium</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior to="/">
                    <div style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/BAAS (3).png' style={{width:"35px",height:"35px"}} />
                      <a>Near</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div   style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/BAAS (4).png' style={{width:"35px",height:"35px"}} />

                      <a>Ethereum</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior to="/">
                    <div   style={{display:"inline-flex", alignItems: "center"}}>

                    <img src='/images/BAAS (5).png' style={{width:"35px",height:"35px"}} />

                      <a>Polkadot</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div   style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/BAAS (6).png' style={{width:"35px",height:"35px"}} />

                      <a>Solana</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div   style={{display:"inline-flex", alignItems: "center"}}>

                    <img src='/images/BAAS (7).png' style={{width:"35px",height:"35px"}} />

                      <a>Tron</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div   style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/BAAS (8).png' style={{width:"35px",height:"35px"}} />

                      <a>Cosmos</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div   style={{display:"inline-flex", alignItems: "center"}}>

                    <img src='/images/BAAS (9).png' style={{width:"35px",height:"35px"}} />

                      <a>Binance Smart Chain</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div   style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/BAAS (10).png' style={{width:"35px",height:"35px"}} />

                      <a>Avalanche</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/BAAS (11).png' style={{width:"35px",height:"35px"}} />
                     <a>Sui</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/BAAS (12).png' style={{width:"35px",height:"35px"}} />

                      <a>Polygon</a>
                      </div></Link>
                  </li>
                 
                  



                 
                </ul>
              </li>
              <li className="menu-item-has-children">
              <div style={{display:"ruby"}}>
                <Link legacyBehavior href="/services" className="drop-down">
                  <a>RAAS</a>

                </Link>

                <img  src={"/images/down-arrow.png"}  style={{width:"20px",height:"20px"}}  onClick={() => toggleMenu("service")}/>
                </div>
                {/* <i
                  className="bi bi-chevron-down dropdown-icon"
                  onClick={() => toggleMenu("service")}
                /> */}

                <ul
                  className={
                    state.activeMenu === "service"
                      ? "sub-menu d-block"
                      : "sub-menu"
                  }
                >
                  <li>
                    <Link legacyBehavior href="/services">
                    <div style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/RAAS1.png'  style={{width:"35px",height:"35px"}}/>
                      <a>RAAS Overview</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/services-two">
                    <div style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/RAAS2.png' style={{width:"35px",height:"35px"}} />

                      <a>Optimistic Rollups - OP Stack</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/services-three">
                    <div style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/RAAS3.png' style={{width:"35px",height:"35px"}} />

                      <a>zkRollups - Polygon CDK</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/services-four">
                    <div style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/RAAS4.png' style={{width:"35px",height:"35px"}} />

                      <a>Optimistic Rollups - Arbitrum Orbit</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/services-five">
                 

                    <div style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/RAAS5.png' style={{width:"35px",height:"35px"}} />

                      <a>zkRollups - zkSync ZK Stack</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/service-details">

                    <div style={{display:"inline-flex", alignItems: "center"}}>
                    <img src='/images/RAAS6.png' style={{width:"35px",height:"35px"}} />

                      <a>Integrations</a></div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
              <div style={{display:"ruby"}}>
                <Link legacyBehavior href="/portfolio-full">
                  <a>NAAS</a>
                </Link>      
                <img  src={"/images/down-arrow.png"}  style={{width:"20px",height:"20px"}}  onClick={() => toggleMenu("portfolio")}/>
                         </div>
                {/* <i
                  className="bi bi-chevron-down dropdown-icon"
                  onClick={() => toggleMenu("portfolio")}
                /> */}
                <ul
                  className={
                    state.activeMenu === "portfolio"
                      ? "sub-menu d-block"
                      : "sub-menu"
                  }
                >
                  <li>
                    <Link legacyBehavior href="/portfolio-masonary">
                    <div  style={{display:"inline-flex", alignItems: "center"}} >
                    <img src='/images/NAAS (1).png' style={{width:"35px",height:"35px"}} />

                      <a>Pryzm Validator Testnet</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/portfolio-full">
                    <div  style={{display:"inline-flex", alignItems: "center"}} >
                    <img src='/images/NAAS (2).png' style={{width:"35px",height:"35px"}} />

                      <a>Babylon Validator Testnet</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/portfolio-details">
                    <div  style={{display:"inline-flex", alignItems: "center"}} >
                    <img src='/images/NAAS (3).png' style={{width:"35px",height:"35px"}} />

                      <a>Celestia Light Node</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/portfolio-details">
                    <div  style={{display:"inline-flex", alignItems: "center"}} >
                    <img src='/images/NAAS (4).png' style={{width:"35px",height:"35px"}} />

                      <a>Elixir Validator Node</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/portfolio-details">

                    <div  style={{display:"inline-flex", alignItems: "center"}} >
                    <img src='/images/NAAS (5).png' style={{width:"35px",height:"35px"}} />

                      <a>Avail Light Client</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/portfolio-details">
                    <div  style={{display:"inline-flex", alignItems: "center"}} >
                    <img src='/images/NAAS (6).png' style={{width:"35px",height:"35px"}} />

                      <a>BEVM Validator Node</a></div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
              <div style={{display:"ruby"}}>
                <Link legacyBehavior href="/blog-column-three">
                  <a>Solutions</a>
                </Link>
                <img  src={"/images/down-arrow.png"}  style={{width:"20px",height:"20px"}}  onClick={() => toggleMenu("blog")}/>
                </div>
                {/* <i
                  className="bi bi-chevron-down dropdown-icon"
                  onClick={() => toggleMenu("blog")}
                /> */}
                <ul
                  className={
                    state.activeMenu === "blog"
                      ? "sub-menu d-block"
                      : "sub-menu"
                  }
                >
                  <li>
                    <Link legacyBehavior href="/blog-column-three">
                    <div style={{display:"inline-flex", alignItems: "center"}}>

                    <img src='/images/solution/Supply Chain.png' style={{width:"35px",height:"35px"}} />
                      <a>Supply Chain</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/blog-column-two">
                    <div style={{display:"inline-flex", alignItems: "center"}}>

                    <img src='images/solution/Counterfeit management.png' style={{width:"35px",height:"35px"}}  />
                      <a>Counterfeit management</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div style={{display:"inline-flex", alignItems: "center"}}>

                    <img  src='images/solution/Real World Asset Tokenisation.png' style={{width:"35px",height:"35px"}}  />
                      <a>Real World Asset Tokenisation</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div style={{display:"inline-flex", alignItems: "center"}}>

                    <img  src='images/solution/Banking and Finance.png' style={{width:"35px",height:"35px"}}  />
                 
                      <a>Banking and Finance</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div style={{display:"inline-flex", alignItems: "center"}}>

                    <img  src='images/solution/Government and public sector.png' style={{width:"35px",height:"35px"}}  />

                      <a>Government and public sector</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div style={{display:"inline-flex", alignItems: "center"}}>

                    <img  src='images/solution/HealthCare.png'  style={{width:"35px",height:"35px"}} />

                      <a>HealthCare</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div style={{display:"inline-flex", alignItems: "center"}}>

                    <img  src='images/solution/Education and training.png' style={{width:"35px",height:"35px"}}  />

                      <a>Education and training</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div style={{display:"inline-flex", alignItems: "center"}}>

                    <img  src='images/solution/Media and Entertainment.png' style={{width:"35px",height:"35px"}} />

                      <a>Media and Entertainment</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div style={{display:"inline-flex", alignItems: "center"}}>

                    <img  src='images/solution/Gaming.png' style={{width:"35px",height:"35px"}} />

                      <a>Gaming</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div style={{display:"inline-flex", alignItems: "center"}}>

                    <img  src='images/solution/ESG and Climate.png'  style={{width:"35px",height:"35px"}} />

                      <a>ESG and Climate</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/">
                    <div style={{display:"inline-flex", alignItems: "center"}}>
                    <img  src='images/solution/Insurance.png' style={{width:"35px",height:"35px"}} />

                      <a>Insurance</a></div>
                    </Link>
                  </li>



                </ul>
              </li>
              <li className="menu-item-has-children">
              <div style={{display:"ruby"}}>
                <Link legacyBehavior href="/blog-column-three">
                  <a>Resources</a>
                </Link>
                <img  src={"/images/down-arrow.png"}  style={{width:"20px",height:"20px"}}  onClick={() => toggleMenu("blog")}/>
                </div>
                {/* <i
                  className="bi bi-chevron-down dropdown-icon"
                  onClick={() => toggleMenu("blog")}
                /> */}
                <ul
                  className={
                    state.activeMenu === "blog"
                      ? "sub-menu d-block"
                      : "sub-menu"
                  }
                >
                  <li>
                    <Link legacyBehavior href="/blog-column-three">
                    <div style={{display:"inline-flex", alignItems: "center"}}>
                    <img    src={"/images/resource (1).png"}  style={{width:"35px",height:"35px"}} />
                      <a>Developer Docs</a></div>
                    </Link>
                  </li>
                  <li>
                    <Link legacyBehavior href="/blog-column-three">
                    <div style={{display:"inline-flex", alignItems: "center"}}>
                      <img  src={"/images/resource (2).png"}  style={{width:"35px",height:"35px"}}/>
                      <a>Case Studies</a></div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link legacyBehavior href="/contact">
                  <a>About Us</a>
                </Link>
              </li>
              <li>
                <Link legacyBehavior href="/contact">
                  <a>Enquiry</a>
                </Link>
              </li>
            </ul>
            {/* mobile-search-area */}
            <div className="d-flex justify-content-start align-items-center flex-row d-lg-none d-block mt-5">
              <Link legacyBehavior href="/service-details">
                <a className="eg-btn btn--md btn--primary sibling-two d-flex d-lg-none">
                  Request a Quote
                </a>
              </Link>
            </div>
          </div>
          <div className="nav-right header-five-right d-flex jsutify-content-end align-items-center gap-sm-5 gap-4">
            {/* <div className="header-icons-four">
              <a href="#" className="search-btn" onClick={toggleModal}>
                <i className="bi bi-search" />
              </a>
              <a href="#" className="cart-icon">
                <i className="bi bi-bag-check" />
              </a>
            </div> */}
            <Link legacyBehavior href="/login">
              <a className="eg-btn  sibling-two d-xl-flex d-none getstarted-btn" style={{color:"#fff"}}>
                Get Started
              </a>
            </Link>
            {/* <div className="header-phone-four">
              <div className="icon">
                <i className="bx bx-phone-call" />
              </div>
              <div className="email">
                <span>Call Now</span>
                <h5>
                  <a href="tel:+998-8776345">+998-8776345</a>
                </h5>
              </div>
            </div> */}
            <div className="mobile-menu-btn d-lg-none d-block">
              {/* <i className="bi bi-list text-dark" /> */}
            <img src='/images/hamburger.png' style={{width:"25px",height:"25px"}}/>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
