// Header.js
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import "./header.css";
import SideDrawer from "./sidebar";
import { useNavigate, Link } from "react-router-dom";
import { MenuItem, Menu } from "@mui/material";
const data = [
  { name: "Home", id: "", href: "/", data: [] },
  {
    name: "Services",
    id: "",
    href: "/",
    data: [],
  },
  {
    name: "BAAS",
    id: "",
    href: "/",
    data: [],
  },
  {
    name: "RAAS",
    id: "",
    href: "/",
    data: [],
  },
  {
    name: "NAAS",
    id: "",
    href: "/",
    data: [],
  },
  {
    name: "Solutions",
    id: "",
    href: "/",
    data: [],
  },
  {
    name: "Resources",
    id: "",
    data: [
      {
        title: "Developer Docs",
        img: "",
        to: "https://doraslabs.gitbook.io/devdoc/",
      },
      {
        title: "Case Studies",
        img: "",
        to: "/",
      },
    ],
  },
  { name: "Enquiry", id: "", href: "/service" },
];

function Header({ click }) {
  const navigate = useNavigate();
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(null);
  const [showMenu, setShowMenu] = useState(null);
  const [menu, setMenu] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event, data) => {
    setAnchorEl(event.currentTarget);
    setShowMenu(data);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setShowMenu(null);
  };
  const toggleSideDrawer = () => {
    setIsSideDrawerOpen(!isSideDrawerOpen);
  };

  useEffect(() => {
    setIsLogin(localStorage.getItem("isLogin"));
  }, [isLogin]);

  return (
    <header className="header">
      <div className="logo">
        <img src="images/logo.png" alt="Logo" onClick={() => navigate("/")} />
      </div>
      <nav className="nav">
        <ul>
          {data.map((data, i) => {
            return (
              <li
                key={i}
                style={{ cursor: "pointer" }}
                onClick={() => click(data.name)}
                onMouseEnter={(event) =>
                  data?.data?.length > 0 && handleMenuOpen(event, data)
                }
                // onMouseLeave={() => handleMenuClose()}
              >
                <Link to={data.href} className="">
                  {data.name}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="buy-button">
        {isLogin ? (
          <button
            className="buy-now"
            onClick={() => {
              localStorage.clear();
              setIsLogin(false);
            }}
          >
            Logout
          </button>
        ) : (
          <button className="buy-now" onClick={() => navigate("/login")}>
            Get Started
          </button>
        )}

        <img src="" alt="" />
        <div className="hamburger-css">
          <MenuIcon onClick={toggleSideDrawer} />
        </div>
      </div>
      <SideDrawer isOpen={isSideDrawerOpen} onClose={toggleSideDrawer} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {showMenu &&
          showMenu.data &&
          showMenu.data.map((item, index) => (
            <MenuItem key={index} onClick={handleMenuClose}>
              {console.log(item , "Item")}
              <Link to={item?.to} target="_blank">
                {item.title}
              </Link>
            </MenuItem>
          ))}
      </Menu>
    </header>
  );
}

export default Header;
