import "../../index.css";
import Header from "../Header copy/NewHeader";
import { Footer } from "../footer/footer";
export const Layout = ({ children }) => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <section>
        <div
          id="rt_banner"
          className="main_mobile_banner"
        >
          {children}
        </div>
      </section>
    </>
  );
};
