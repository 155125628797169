import React, { useEffect, useState } from "react";
// import Header from "../../components/Header copy/NewHeader";
import Header from "../../components/header/header";
import { Banner } from "./banner";
import { Preffered } from "./preffered";
import { About } from "./about";
import { Service } from "./services";
import { HeroSection } from "./herosection";
import { Solution } from "./solution";
import { CaseStudy } from "./case";
import { NodeService } from "./nodeService";
import { BlockChainService } from "./services";
import { Footer } from "../../components/footer/footer";
import { Protocols } from "./protocol";
import { RollUpService } from "./rollUpService";
import { ChooseUs } from "./chooseUs";
import ParallaxSlider from "../../components/parallax/Parallax";

// import RaasSlider from "./rollUpService";
import "../../index.css";
const Homepage = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div id="__next">
      
           <Header/>
      <ParallaxSlider/>
      <ChooseUs/>
      {/* <BlockChainService /> */}
      
 
    
      <RollUpService />
      <NodeService />
      <Solution />
      <CaseStudy />
     <Protocols />
      <Footer />
    </div>
  );
};

export default Homepage;
