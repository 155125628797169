import React from "react";
import { Grid } from "@mui/material";
import Header from "../../components/Header copy/NewHeader";
import "./index.css";
const marketplaceData = [
  { title: "Pryzm Validator Testnet" },
  { title: "Elixir Validator" },
  { title: "BEVM Archive Node" },
  { title: "Celestia Light Node" },
  { title: "Avail Light Client" },
  { title: "Avail Validator Node" },
  { title: "Avail Full Node" },
  { title: "Shardeum Testnet Validator " },
  { title: "DeSo Node" },
  { title: "AR.IO.Envoy" },
  { title: "AR.IO.Core" },
  { title: "AR.IO.Observer" },
  { title: "Chia Blockchain" },
];
const planData = [
  {
    title: "Glacies Nano",
    price: "$16.51",
    duration: "monthly",
    rate: "$0.02 hourly cost",
    specification: ["2 CPU", "0.5Gi RAM"],
  },
  {
    title: "Glacies Nano",
    price: "$16.51",
    duration: "monthly",
    rate: "$0.02 hourly cost",
    specification: ["2 CPU", "0.5Gi RAM"],
  },
  {
    title: "Glacies Nano",
    price: "$16.51",
    duration: "monthly",
    rate: "$0.02 hourly cost",
    specification: ["2 CPU", "0.5Gi RAM"],
  },
  {
    title: "Glacies Nano",
    price: "$16.51",
    duration: "monthly",
    rate: "$0.02 hourly cost",
    specification: ["2 CPU", "0.5Gi RAM"],
  },
];
const storageData = [
  {
    title: "Storage 0.25 GB",
    rate: "$0.01/month",
  },
  {
    title: "Storage 0.25 GB",
    rate: "$0.01/month",
  },
  {
    title: "Storage 0.25 GB",
    rate: "$0.01/month",
  },
  {
    title: "Storage 0.25 GB",
    rate: "$0.01/month",
  },
  {
    title: "Storage 0.25 GB",
    rate: "$0.01/month",
  },
  {
    title: "Storage 0.25 GB",
    rate: "$0.01/month",
  },
  {
    title: "Storage 0.25 GB",
    rate: "$0.01/month",
  },
];

const RollupForm = () => {
  const handleSubmit = (values) => {
    // Handle form submission here
    console.log("Form submitted:", values);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <Header click={scrollToSection}/>
      <div className="node_main">
        <h3>Node As A Service</h3>

        <div className="node_main_form">
          {/* what are looking for section start */}
          <div className="node_label_form_box">
            <label>{"What are you looking for?"}</label>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div className="node_card">
                  <p>Computer</p>
                  <p>Run containers on CPU- based instance</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="node_card">
                  <p>Accelerate</p>
                  <p>GPU-Powered Computation Boost</p>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* what are looking for section ends */}
          {/* Compute Type  (Scale Hardware Resources) starts */}
          <div className="node_label_form_box">
            <label>{"Compute Type  (Scale Hardware Resources)"}</label>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <div className="node_card">
                  <p>On Demand</p>
                  <p>Resources can be manually scaled </p>
                  <p>Learn more</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="node_card">
                  <p>Autoscale</p>
                  <p>Resources are automatically scaled </p>
                  <p>Learn ore</p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="node_card">
                  <p>Spot</p>
                  <p>Resources can’t be scaled after the deployment</p>
                  <p>Learn more</p>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* Compute Type  (Scale Hardware Resources) ends */}
          {/* Create a new Cluster starts */}
          <div className="node_label_form_box">
            <label>{"Create a new Cluster"}</label>
            <input placeholder="Start from marketplace app" />
          </div>
          {/* Create a new Cluster ends */}
          {/* Start from Marketplace App starts */}
          <div className="node_label_form_box">
            <label>{"Start from Marketplace App"}</label>
            <Grid container spacing={2}>
              {marketplaceData.map((data) => {
                return (
                  <Grid item xs={12} sm={6}>
                    <div className="node_card">
                      <p>{data.title}</p>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
          {/* Start from Marketplace App ends */}
          {/* Region starts */}
          <div className="node_label_form_box">
            <label>{"Region"}</label>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <select>
                  <option value="">Select Region</option>
                  <option value="POW">US-EAST</option>
                  <option value="POA">US-WEST</option>
                </select>
              </Grid>
            </Grid>
          </div>
          {/* Region ends */}
          {/* Select Instance Plan starts */}
          <div className="node_label_form_box">
            <label>{"Select Instance Plan"}</label>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <input placeholder="Start from marketplace app" />
              </Grid>
            </Grid>
          </div>
          <div className="node_label_form_box">
            <Grid container spacing={2}>
              {planData.map((data) => {
                return (
                  <Grid item xs={12} sm={6}>
                    <div className="node_card">
                      <h5>{data.title}</h5>
                      <h4>
                        {data.price}
                        <span>{data.duration}</span>
                      </h4>
                      <p>{data.rate}</p>
                      <ul>
                        {data.specification.map((spec) => (
                          <li>{spec}</li>
                        ))}
                      </ul>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
          {/* Select Instance Plan ends */}
          {/* Configure Storage   starts */}
          <div className="node_label_form_box">
            <label>{"Configure Storage"}</label>
            <Grid container spacing={2}>
              {storageData.map((data) => {
                return (
                  <Grid item xs={12} sm={6}>
                    <div className="node_card">
                      <h5>{data.title}</h5>
                      <p>{data.rate}</p>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
          {/* Configure Storage  ends */}
          {/* Configuration starts */}
          <div className="node_label_form_box">
            <label>{"Configuration"}</label>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <div className="configuration_second_node_card">
                  <label className="configuration_inner_label">
                    {"Configuration"}
                  </label>
                  <div className="configuration_form_div">
                    <Grid container spacing={2}>
                      {" "}
                      <Grid item xs={12} sm={6}>
                        <div className="node_label_form_box">
                          <label className="configuration_input_label">
                            {"Node Type"}
                          </label>
                          <input
                            className="configuration_input"
                            placeholder="light"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      {" "}
                      <Grid item xs={12} sm={6}>
                        <div className="node_label_form_box">
                          <label className="configuration_input_label">
                            {"Network"}
                          </label>
                          <input
                            className="configuration_input"
                            placeholder="light"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      {" "}
                      <Grid item xs={12} sm={6}>
                        <div className="node_label_form_box">
                          <label className="configuration_input_label">
                            {"RPC URL"}
                          </label>
                          <input
                            className="configuration_input"
                            placeholder="light"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="configuration_hr_line"></div>
                  <div className="configuration_form_div">
                    <Grid container spacing={2}>
                      {" "}
                      <Grid item xs={12} sm={6}>
                        <div className="node_label_form_box">
                          <label className="configuration_input_label">
                            {"Replicas"}
                          </label>
                          <input
                            className="configuration_input"
                            placeholder="light"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      {" "}
                      <Grid item xs={12} sm={6}>
                        <div className="node_label_form_box">
                          <label className="configuration_input_label">
                            {"PORT POLICY MAPPING "}
                          </label>
                          <input
                            className="configuration_input"
                            placeholder="light"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      {" "}
                      <Grid item xs={12} sm={6}>
                        <div className="node_label_form_box">
                          <label className="configuration_input_label">
                            {"PORT POLICY MAPPING "}
                          </label>
                          <input
                            className="configuration_input"
                            placeholder="light"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="configuration_hr_line"></div>
                  <label className="configuration_inner_label">
                    {"Advanced Configuration"}
                  </label>
                  <div className="configuration_form_div">
                    <Grid container spacing={2}>
                      {" "}
                      <Grid item xs={12} sm={6}>
                        <div className="node_label_form_box">
                          <label className="configuration_input_label">
                            {"Commands"}
                          </label>
                          <input
                            className="configuration_input"
                            placeholder="sh"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      {" "}
                      <Grid item xs={12} sm={6}>
                        <div className="node_label_form_box">
                          {/* <label className="configuration_input_label">{"PORT POLICY MAPPING "}</label> */}
                          <input
                            className="configuration_input"
                            placeholder="-c"
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      {" "}
                      <Grid item xs={12} sm={6}>
                        <div className="node_label_form_box">
                          {/* <label className="configuration_input_label">{"PORT POLICY MAPPING "}</label> */}
                          <input
                            className="configuration_input"
                            placeholder="celestia ${NODE_TYPE} init --p2p.network ${"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={5}>
                <div className="configuration_second_node_card">
                  <label className="configuration_inner_label">
                    {"Glacies Nano"}
                  </label>
                  <div className="configuration_form_div">
                    <div className="node_label_form_box">
                      <input
                        className="configuration_input height_54"
                        placeholder="Celestia Light Node"
                      />
                    </div>
                  </div>
                  <div className="configuration_hr_line"></div>
                  <div className="glacies_div">
                    <div className="glacies_inner_div">
                      <label className="glacies_label">Glacies Nano</label>
                      <label className="glacies_label">$16.51</label>
                    </div>
                    <div className="configuration_hr_line"></div>
                    <div className="glacies_inner_div">
                      <label className="glacies_label">
                        20 GB SSD Storage{" "}
                      </label>
                      <label className="glacies_label">$0.87</label>
                    </div>
                    <div className="configuration_hr_line"></div>
                    <div className="glacies_inner_div">
                      <label className="glacies_label">Sub-Total</label>
                      <label className="glacies_label">$17.38 x1</label>
                    </div>
                    <div className="configuration_hr_line"></div>
                    <div className="glacies_inner_div">
                      <label className="glacies_label">Total</label>
                      <label className="glacies_label">$17.38 </label>
                    </div>
                  </div>
                  <button className="deploy_button">Deploy</button>
                  <p className="glacies_p">
                    The prices here are just estimates , they will be different
                    past deployment
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
          {/* Configuration ends */}
        </div>
      </div>
    </div>
  );
};

export default RollupForm;
