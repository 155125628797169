import React, { useState } from "react";
import { Grid } from "@mui/material";
import Radio from "@mui/material/Radio/Radio";
import "./css/protocol.css";

const data = [
  {
    title: "Ethereum",
    statement:
      "Deploy the blockchain nodes in your own secure multi-cloud hosting environment. Access dozens of pre-built tools and services to rapidly built and scale blockchain solutions.",
    learn: "Learn more",
  },
  {
    title: "Corda",
    statement:
      "Deploy the blockchain nodes in your own secure multi-cloud hosting environment. Access dozens of pre-built tools and services to rapidly built and scale blockchain solutions.",
    learn: "Learn more",
  },
  {
    title: "Hyperledger Fabric",
    statement:
      "Deploy the blockchain nodes in your own secure multi-cloud hosting environment. Access dozens of pre-built tools and services to rapidly built and scale blockchain solutions.",
    learn: "Learn more",
  },
];

const Protocol = ({ setTab }) => {
  const [protocol, setProtocol] = useState({
    title: "Ethereum",
    statement:
      "Deploy the blockchain nodes in your own secure multi-cloud hosting environment. Access dozens of pre-built tools and services to rapidly built and scale blockchain solutions.",
    learn: "Learn more",
  });
  const handleSubmit = () => {
    if (protocol) {
      setTab("Provider");
    }
  };
  return (
    <div className="protocol_main">
      <div>
        <h3>Select a Protocol</h3>
        <p className="white_color">
          {"Choose the core blockchain technology for the environment "}
        </p>
      </div>
      <div className="protocol_hr_line"></div>
      <div className="protocol_main_form">
        <Grid container spacing={2}>
          {data.map((item, i) => (
            <Grid
              item
              xs={12}
              md={item.title == "Ethereum" ? 12 : 6}
              key={i}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className={
                  i == 0
                    ? "protocol_card active_border"
                    : "protocol_card diable_card"
                }
                onClick={() => {
                  i == 0 && setProtocol(item);
                }}
              >
                <div className="radio_div">
                  <Radio
                    name="region"
                    checked={protocol.title == item.title}
                    style={{ color: "#A74505" }}
                  />
                </div>
                <div className="content_div">
                  <p className="text_Align_left card_heading">{item.title}</p>
                  <p className="text_Align_left">{item.statement}</p>
                  <p className="text_Align_left learn_more_color">
                    {item.learn}
                  </p>
                </div>
              </div>
            </Grid>
          ))}
          {/* <Grid
            item
            xs={12}
            sm={6}
            style={{ textAlign: "center", marginTop: 40 }}
          >
            <button
            
              className="setup_node_button border_none"
            >
              Cancel
            </button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ textAlign: "center", marginTop: 40 }}
          >
            <button  className="setup_node_button">
              Next
            </button>
          </Grid> */}
          <div className="flex-center-box">
            <button
              className="network_details_button"
              onClick={() => setTab("EnvironmentType")}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              className="network_details_button"
            >
              Next
            </button>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default Protocol;
