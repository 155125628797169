import { Link } from "react-router-dom";
import { useState } from "react";
import { config } from "../../config";
import { post } from "../../service/apiService";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OtpVerification from "./verifyOtp";
import toast from "react-hot-toast";
import Header from "../../components/header/header.js";
// import Header2 from "../../components/header/Header";
import { GoogleLogin } from "@react-oauth/google";
import * as Yup from "yup";
import "./index.css";
import "../../index.css";
const { login, googleLogin } = config.authEndPoints;
const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setErrors] = useState({});
  const [passwordShow, setPasswordShow] = useState(false);
  const [message, setMessage] = useState("");
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email"),
    password: Yup.string(),
  });
  const navigate = useNavigate();
  const handleChange = async (e) => {
    const { name, value } = e.target;
    try {
      await Yup.reach(validationSchema, name).validate(value);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
    }
    setFormData((prebvState) => ({ ...prebvState, [name]: value }));
  };
  const handleFormSubmit = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const response = await post(login, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { message, responseData, success } = response;
      if (success) {
        toast.success("Login Successfully");
        localStorage.setItem("token", responseData?.token);
        localStorage.setItem("isLogin", true);
        navigate("/service");
      } else {
        toast.error(message);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message;
      });
      setErrors(validationErrors);
    }
  };

  const handleGoogleLogin = async (data) => {
    const { clientId, credential } = data;
    console.log(data, "Data Hain ???");
    try {
      const response = await post(
        googleLogin,
        {
          credential: credential,
          clientId: clientId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { message, responseData, success } = response;
      if (success) {
        toast.success("Login Successfully");
        localStorage.setItem("token", responseData?.token);
        localStorage.setItem("isLogin", true);
        navigate("/service");
      } else {
        toast.error(message);
      }
    } catch (error) {}
  };
  return (
    <div id="__next">
      <Header/>
      <div id="rt_contact">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 col-md-offset-2"
              style={{ margin: "0 auto" }}
            >
              <div className="contact_form">
                <div class="mt_heading_white" style={{ marginBottom: "20px" }}>
                  <h2 class="section_heading text-center">
                    <span class="heading_text">Login</span>
                  </h2>
                </div>
                <div>
                  <div className="row">
                    <div className="col-sm-12">
                      <label>Email:</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter your email id"
                        required={true}
                        onChange={handleChange}
                      />
                      {error?.email && (
                        <span style={{ color: "red" }}>{error?.email}</span>
                      )}
                    </div>
                    <div className="col-sm-12">
                      <label>Password:</label>
                      <div style={{ position: "relative" }}>
                        <input
                          type={passwordShow ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Enter your password "
                          required=""
                          onChange={handleChange}
                        />
                        <img
                          src={
                            passwordShow
                              ? "/icons/showPassword.svg"
                              : "/icons/password-hide.svg"
                          }
                          className="password-icon"
                          onClick={() => setPasswordShow(!passwordShow)}
                          alt=""
                        />
                      </div>
                      <div>
                        <div className="col-sm-6">
                          <input type="checkbox" />
                          <span style={{  marginLeft: "10px" }}>
                            Remember me
                          </span>
                        </div>
                        <Link
                          to={"/forgot-password"}
                          className="col-sm-6 text-right"
                        >
                          Forgot Password
                        </Link>
                      </div>
                    </div>

                    <div className="col-sm-12" style={{ marginTop: "20px" }}>
                      <button
                        className="rt_btn_color"
                        id="submit-btn"
                        style={{ width: "100%", textAlign: "center" }}
                        onClick={handleFormSubmit}
                      >
                        Login
                        <span className="mt_load">
                          <span></span>
                        </span>
                      </button>
                      <div id="msg"></div>
                    </div>
                    <div className="col-sm-12" style={{ position: "relative" }}>
                      <hr style={{ color: "#000" }} />
                      <span className="or-text">Or</span>
                    </div>
                    <div
                      style={{
                        margin: "10px 0px",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <GoogleLogin
                        onSuccess={(credentialResponse) => {
                          handleGoogleLogin(credentialResponse);
                        }}
                        shape={"pill"}
                        onError={() => {
                          console.log("Login Failed");
                        }}
                        className="google-login-button"
                        style={{ border: "none" }}
                      />
                    </div>
                    <Link
                      to={"/register"}
                      style={{
                        textDecoration: "none",
                        display: "block",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <p className="text-center" style={{ color: "#000" }}>
                        Create &nbsp;
                        <span style={{ color: "#9A572D" }}>Blocknest</span>
                        &nbsp; Account
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
