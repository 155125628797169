import { post } from "../../service/apiService";
import { config } from "../../config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Header2 from "../../components/header/header";
const { otpVerification } = config.authEndPoints;
const OtpVerification = ({ email, changeState, type }) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate()
  const handleVerify = async () => {
    if (otp.length === 6) {
      try {
        const response = await post(
          otpVerification,
          { email: email, otp: otp },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.success) {
          if (type) {
            localStorage.setItem("token", response?.responseData?.token);
            localStorage.setItem("isLogin", true);
            navigate('/service');
            toast.success("Otp Verify Successfully");
            toast.success("Login Successfully");
          } else {
            localStorage.setItem("token", response?.responseData?.token);
            toast.success("Otp Verify Successfully");
            changeState();
          }
        } else {
          toast.error(response?.message)
        }
      } catch (error) {}
    }
  };
  return (
    <div id="__next">
      <Header2/>
      <div id="rt_contact">
        <div className="container">
          <div className="row">
            <div
              className="col-md-6 col-md-offset-2"
              style={{ margin: "0 auto" }}
            >
              <div className="contact_form">
                <div class="mt_heading_white" style={{ marginBottom: "20px" }}>
                  <h2 class="section_heading text-center">
                    <span class="heading_text">OTP Verification</span>
                  </h2>
                </div>
                <div id="contact_form">
                  <div className="row">
                    <div className="col-sm-12">
                      <label>OTP</label>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="OTP Verification"
                        required={true}
                        minLength={6}
                        maxLength={6}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-12">
                      <button
                        className="rt_btn_color"
                        id="submit-btn"
                        style={{ width: "100%", textAlign: "center" }}
                        onClick={handleVerify}
                      >
                        Submit
                        <span className="mt_load">
                          <span></span>
                        </span>
                      </button>
                      <div id="msg"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OtpVerification;
