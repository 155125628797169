// import logo from "./logo.png";
import "./App.css";
import "./index.css";
import Homepage from "./pages/homepage";
import BlockchainDevelopment from "./pages/blockchainDevelopment";
import RollsUp from "./pages/rollsup";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Suspense } from "react";
import Login from "./pages/auth/login";
import { Layout } from "./components/layout/layout";
import ForgotPassword from "./pages/auth/forgotPassword";
import Register from "./pages/auth/register";
import { ComingSoon } from "./components/comingSoon/ComingSoon";
import { ContactUs } from "./pages/contact/contact";
import { Toaster } from "react-hot-toast";
import Node from "./pages/nodeService";
import Network from "./pages/networkDetails/index";
import { ServicePage } from "./pages/service/service";
import PrivateRoute from "./components/privateRoute/privateRoute";
import { PlugAndPlay } from "./pages/plug/PlugAndPlay";
import { useEffect } from "react";
import { Loader } from "./components/loader/loader";
import NodeDeployed from "./pages/networkDetails/nodeDeployed";
import { AboutUs } from "./pages/aboutUs/about";
function App() {
  return (
    <>
      <Router>
        <ScrollTop />
        <Toaster />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Homepage />} />
            {/* <Route path="/" element={<ComingSoon/>}/> */}
            <Route exact path="/" element={<PrivateRoute />}>
              <Route
                path="/blockchain-development"
                element={
                  <Layout>
                    <BlockchainDevelopment />
                  </Layout>
                }
              />
              <Route
                path="/rollsup"
                element={
                  <Layout>
                    <RollsUp />
                  </Layout>
                }
              />
              <Route
                path="/node-as-service"
                element={
                  <Layout>
                    <Node />
                  </Layout>
                }
              />
            </Route>
            <Route
              path="/service"
              element={
                // <Layout>
                <PlugAndPlay />
                // </Layout>
              }
            />
            <Route
              path="/network-details"
              element={
                <Layout>
                  <Network />
                </Layout>
              }
            />
            <Route
              path="/register"
              element={
                <Layout>
                  <Register />
                </Layout>
              }
            />
            <Route
              path="/login"
              element={
                <Layout>
                  <Login />
                </Layout>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <Layout>
                  <ForgotPassword />
                </Layout>
              }
            />
            <Route
              path="/node-deployed"
              element={
                <Layout>
                  <NodeDeployed />
                </Layout>
              }
            />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
          </Routes>
        </Suspense>
      </Router>{" "}
    </>
  );
}
const ScrollTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);
  return null;
};

export default App;
