import "../../index.css";
import "../../App.css";
import Header from "../../components/Header copy/NewHeader";
import { Footer } from "../../components/footer/footer";
export const AboutUs = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <Header click={scrollToSection} />
      <div id="rt_welcome" style={{ paddingTop: "0px" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6" style={{ margin: "40px auto" }}>
              <img src="/images/about-1.svg" alt="" />
              <div className="banner_caption_text">
                <h1 className="text-center" style={{ fontWeight: "600" }}>
                  Blockchain As A Service
                </h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12  col-lg-4 about_sec_img">
              <div className="mt_about_img">
                <img src="/images/about-2.svg" alt="About Image" />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8">
              <div className="about_services">
                <div className="section_heading_about">
                  <h3>We see what others don’t see</h3>
                </div>
                <div className="rt_about_us">
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        DORAS offers access to various blockchain networks,
                        platforms and protocols, allowing clients to connect and
                        engage with blockchain technology.
                      </p>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        It provides easy-to-use interfaces, APIs (Application
                        Programming interfaces), or SDKs, (Software Development
                        Kits) that enable clients to interact with blockchain
                        systems seamlessly.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        DORAS may also assist clients in setting up their
                        blockchain nodes or connecting to existing networks,
                        ensuring smooth access to blockchain infrastructure.
                      </p>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                    <div className="rt_service_box">
                      <i className="icon-briefcase"></i>
                      <h5>Initial Consultation</h5>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit, sed do eiusmod.
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-8">
              <div className="about_services">
                <div className="section_heading_about">
                  {/* <span>Why Choose Us</span> */}
                  <h3>Opportunity Identification and Facilitation</h3>
                </div>
                <div className="rt_about_us">
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        DORAS helps clients identify potential opportunities for
                        leveraging blockchain technology within their industries
                        or business processes.
                      </p>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        Through market research, analysis and consultation,
                        DORAS assists clients in understanding how blockchain
                        can address specific pain points, improve efficiency, or
                        unlock new revenue streams.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        It Offers strategic guidance and recommendations on how
                        clients can capitalize on blockchain opportunities,
                        whether through enhancing existing processes or
                        exploring new business models.
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12  col-lg-4 about_sec_img">
              <div className="mt_about_img">
                <img src="/images/about-3.svg" alt="About Image" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12  col-lg-4 about_sec_img">
              <div className="mt_about_img">
                <img src="/images/about-4.svg" alt="About Image" />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8">
              <div className="about_services">
                <div className="section_heading_about">
                  <h3>Rapid Solution Development and Implementation</h3>
                </div>
                <div className="rt_about_us">
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        DORAS collaborates with clients to design, develop, and
                        implement tailored blockchain solutions that address
                        their unique needs and objectives.
                      </p>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        It offers end-to-end blockchain development services,
                        including solution architecture, smart contract
                        development, decentralized application (dApp) creation,
                        and integration with existing systems.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        It Offers strategic guidance and recommendations on how
                        clients can capitalize on blockchain opportunities,
                        whether through enhancing existing processes or
                        exploring new business models.
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-8">
              <div className="about_services">
                <div className="section_heading_about">
                  <h3>Awareness and Education</h3>
                </div>
                <div className="rt_about_us">
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        DORAS educates clients and stakeholders about blockchain
                        technology, its capabilities, and potential
                        applications.
                      </p>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        Through workshops, seminars, webinars, and educational
                        materials, DORAS helps demystify blockchain concepts and
                        foster a deeper understanding of its implications for
                        businesses and industries.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        It may also provide thought leadership, industry
                        insights, and case studies to inspire innovation and
                        drive adoption of blockchain technology.
                      </p>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12  col-lg-4 about_sec_img">
              <div className="mt_about_img">
                <img src="/images/about-5.svg" alt="About Image" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12  col-lg-4 about_sec_img">
              <div className="mt_about_img">
                <img src="/images/about-6.svg" alt="About Image" />
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8">
              <div className="about_services">
                <div className="section_heading_about">
                  <h3>Support and Maintenance</h3>
                </div>
                <div className="rt_about_us">
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        DORAS provides ongoing support and maintenance services
                        to ensure the smooth operation of blockchain solutions
                        post-deployment.
                      </p>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        It offers troubleshooting assistance, performance
                        monitoring, and regular updates to keep blockchain
                        systems up-to-date and resilient against emerging
                        threats.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <div className="rt_service_box">
                      <p>
                        DORAS may also offer training and knowledge transfer to
                        empower clients' internal teams in managing and
                        maintaining blockchain solutions effectively.
                      </p>

                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
