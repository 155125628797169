import { Link } from "react-router-dom";
import Slider from "react-slick";
import "../../index.css";
import "./Index.css";

var settings = {
  dots: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 1000, 
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: "40px",
        slidesToShow: 1,
      },
    },
  ],
};
export const NodeService = () => {
  return (
    <section id="NAAS">
      <div id="rt_welcome">
        <div className="section_heading_about text-center">
          <img src="" alt="" />
          <h3 className="heading_text text-center">Node As A Service</h3>
          <p className="heading_txt text-center">
            Node as a Service (NaaS) offers
          </p>

          <div className="slick-cont">
            <Slider {...settings}>
              <div className="naas-slide">
                <div className="flex gap-2">
                  <img
                    src="/icons/pryzm.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Pryzm Validator Testnet</h4>
                </div>
                <p>
                  Prysm is an Ethereum 2.0 client that allows users to run a
                  validator node on the Ethereum 2.0 testnet.
                </p>
              </div>

              <div className="naas-slide">
                <div className="flex gap-2">
                  <img
                    src="/icons/babylon.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Babylon Validator Testnet</h4>
                </div>
                <p>
                  The Babylon validator testnet allows individuals to
                  participate in the Babylon blockchain ecosystem by becoming
                  validators on the testnet.
                </p>
              </div>
              <div className="naas-slide">
                <div className="flex gap-2">
                  <img
                    src="/icons/calestia.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Celestia Light Node</h4>
                </div>
                <p>
                  A Celestia light node is designed to allow users to interact
                  with the celestia blockchain network without requiring the
                  resources needed for running a full node.
                </p>
              </div>

              <div className="naas-slide">
                <div className="flex gap-2">
                  <img
                    src="/icons/elixir.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Elixir Validator Node</h4>
                </div>
                <p>
                  An Elixir validator node is a crucial component of the Elixir
                  blockchain network responsible for executing the underlying
                  algorithm and achieving consensus within the network.
                </p>
              </div>

              <div className="naas-slide">
                <div className="flex gap-2">
                  <img
                    src="/icons/avail.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>Avail Light Client</h4>
                </div>
                <p>
                  The Avail Light Client provides a decentralized and secure way
                  to interact with the Avail blockchain without needing
                  traditional full nodes, ensuring data availability on the
                  network.
                </p>
              </div>

              <div className="naas-slide">
                <div className="flex gap-2">
                  <img
                    src="/icons/bevm.svg"
                    alt=""
                    className="icon-briefcase"
                  />
                  <h4>BEVM Validator Node</h4>
                </div>
                <p>
                  The BEVM validator node is responsible for participating in
                  consensus and validating transactions on the network.
                </p>
              </div>
            </Slider>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "70px",
          }}
        >
          <Link className="gradient_btn" to="/service">
            <span>Book An Appointment</span>{" "}
          </Link>
        </div>
      </div>
    </section>
  );
};
