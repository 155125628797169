// SideDrawer.js
import React from "react";
import "./sideBar.css"; // Import your CSS for styling
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, Link } from "react-router-dom";

const data = [
  { name: "Home", id: "", href: "/", to: "Home" },
  { name: "Services", id: "", href: "/", to: "Services" },
  { name: "BAAS", id: "", href: "/", to: "BAAS" },
  { name: "RAAS", id: "", href: "/", to: "RAAS" },
  { name: "NAAS", id: "", href: "/", to: "NAAS" },
  { name: "Solutions", id: "", href: "/", to: "Solutions" },
  { name: "Resources", id: "", href: "/", to: "" },
  { name: "Enquiry", id: "", href: "/contact", to: "" },
];

function SideDrawer(props) {
  const navigate = useNavigate();
  const { isOpen, onClose } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (params) => {
    const section = document.getElementById(params);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    onClose()
  };

  return (
    <div className={`side-drawer ${isOpen ? "open" : ""}`}>
      <nav className="side_nav">
        <div className="close-div" onClick={onClose}>
          <CloseIcon />
        </div>
        <ul>
          {data.map((data, i) => {
            return (
              <li key={i} onClick={() => handleSelect(data.to)}>
                <Link to={data.href}>{data.name}</Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}

export default SideDrawer;
