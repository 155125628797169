import React from "react";
import { useRef } from "react";
import { useSpring, animated } from "react-spring";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Parallax.css";

const ParallaxSlider = () => {
  // Define animations for the slides
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  return (
    // <div className="parallax-slider-container" onMouseMove={handleMouseMove}>
    <Swiper
    //   spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      onAutoplayTimeLeft={onAutoplayTimeLeft}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="relativeBox">
          <img src="/images/Slide-1.webp" alt="Slide 2" />
          <div id="overlay">
            <h1>
              Reimagined The Way Businesses Interact With Blockchain Technology
            </h1>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relativeBox">
          <img src="/images/Slide-2.webp" alt="Slide 2" />
          <div id="overlay">
            <h1>
              Reimagined The Way Businesses Interact With Blockchain Technology
            </h1>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relativeBox">
          <img src="/images/Slide-3.webp" alt="Slide 2" />
          <div id="overlay">
            <h1>
              Reimagined The Way Businesses Interact With Blockchain Technology
            </h1>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relativeBox">
          <img src="/images/Slide-4.webp" alt="Slide 2" />
          <div id="overlay">
            <h1>
              Reimagined The Way Businesses Interact With Blockchain Technology
            </h1>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relativeBox">
          <img src="/images/Slide-5.webp" alt="Slide 2" />
          <div id="overlay">
            <h1>
              Reimagined The Way Businesses Interact With Blockchain Technology
            </h1>
          </div>
        </div>
      </SwiperSlide>
      <div className="autoplay-progress" slot="container-end">
        <svg viewBox="0 0 48 48" ref={progressCircle}>
          <circle cx="24" cy="24" r="20"></circle>
        </svg>
        <span ref={progressContent}></span>
      </div>
    </Swiper>
  );
};

export default ParallaxSlider;
