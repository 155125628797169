// import React from "react";
// import { Formik, Field, Form, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import { post } from "../../service/apiService";
// import { config } from "../../config";
// import { Grid } from "@mui/material";
// import toast from "react-hot-toast";
// import Header from "../../components/Header copy/NewHeader";
// import "./index.css";
// const { rollUp } = config.authEndPoints;

// const validationSchema = Yup.object().shape({
//   email: Yup.string().email("Invalid email").required("Email is required"),
//   phone: Yup.string().required("Phone Number is required"),
//   rollup: Yup.string().required("Rollup Name is required"),
//   execution: Yup.string().required("Execution is required"),
//   sequencer: Yup.string().required("Sequencer is required"),
//   dataAvailability: Yup.string().required("Data Availability is required"),
//   interoperability: Yup.string().required("Interoperability is required"),
// });

// const initialValues = {
//   email: "",
//   phone: "",
//   rollup: "",
//   execution: "",
//   sequencer: "",
//   dataAvailability: "",
//   interoperability: "",
// };

// const inputData = [
//   {
//     label: "Email:",
//     name: "email",
//   },
//   {
//     label: "Phone Number:",
//     name: "phone",
//   },
//   {
//     label: "Rollup Name",
//     name: "rollup",
//   },
// ];

// const RollupForm = () => {
//   const handleSubmit = async (values) => {
//     // Handle form submission here
//     try {
//       const response = await post(rollUp, values, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       if (response?.success) {
//         toast.success(
//           "Your form submission has been successful. Our team will connect with you as soon as possible."
//         );
//       } else {
//         toast.error(response?.message);
//       }
//     } catch (error) {}
//     console.log("Form submitted:", values);
//   };
//   const scrollToSection = (sectionId) => {
//     const section = document.getElementById(sectionId);
//     if (section) {
//       section.scrollIntoView({ behavior: "smooth" });
//     }
//   };
//   return (
//     <div>
//       <Header click={scrollToSection} />
//       <div className="rollsup_main">
//         <h3>Roll-Up As A Service</h3>

//         <div className="rollsup_main_form">
//           <Formik
//             initialValues={initialValues}
//             validationSchema={validationSchema}
//             onSubmit={handleSubmit}
//           >
//             <Form>
//               <Grid container spacing={2}>
//                 {inputData.map((data, i) => (
//                   <Grid item xs={12} sm={6} key={i}>
//                     <div className="rollsup_label_form_box">
//                       <label>{data.label}</label>
//                       <Field type="text" name={data.name} />
//                       <ErrorMessage
//                         name={data.name}
//                         component="div"
//                         style={{ color: "red" }}
//                       />
//                     </div>
//                   </Grid>
//                 ))}

//                 <Grid item xs={12} sm={6}>
//                   <div className="rollsup_label_form_box">
//                     <label>{"Execution:"}</label>
//                     <Field as="select" name="execution">
//                       <option value="">Select Option</option>
//                       <option value="Geth">Geth</option>
//                       <option value="BSC">BSC</option>
//                     </Field>
//                     <ErrorMessage
//                       name="execution"
//                       component="div"
//                       style={{ color: "red" }}
//                     />
//                   </div>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <div className="rollsup_label_form_box">
//                     <label>{"Sequencer:"}</label>
//                     <Field as="select" name="sequencer">
//                       <option value="">Select Option</option>
//                       <option value="POW">POW</option>
//                       <option value="POA">POA</option>
//                     </Field>
//                     <ErrorMessage
//                       name="sequencer"
//                       component="div"
//                       style={{ color: "red" }}
//                     />
//                   </div>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <div className="rollsup_label_form_box">
//                     <label>{"Data Availability:"}</label>
//                     <Field as="select" name="dataAvailability">
//                       <option value="">Select Option</option>
//                       <option value="POW">POW</option>
//                       <option value="POA">POA</option>
//                     </Field>
//                     <ErrorMessage
//                       name="dataAvailability"
//                       component="div"
//                       style={{ color: "red" }}
//                     />
//                   </div>
//                 </Grid>
//                 <Grid item xs={12} style={{ textAlign: "center" }}>
//                   <div className="rollsup_label_form_box">
//                     <label>{"Interoperability:"}</label>
//                     <Field as="select" name="interoperability">
//                       <option value="">Select Option</option>
//                       <option value="POW">POW</option>
//                       <option value="POA">POA</option>
//                     </Field>
//                     <ErrorMessage
//                       name="interoperability"
//                       component="div"
//                       style={{ color: "red" }}
//                     />
//                   </div>
//                 </Grid>

//                 <Grid
//                   item
//                   xs={12}
//                   style={{ textAlign: "center", marginTop: 40 }}
//                 >
//                   <button type="submit" className="rollsup_button">
//                     Submit
//                   </button>
//                 </Grid>
//               </Grid>
//             </Form>
//           </Formik>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RollupForm;

import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import { post, get } from "../../service/apiService";
import { config } from "../../config";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// import Header from "../../components/Header copy/NewHeader";
import { Footer } from "../../components/footer/footer";
// import Header2 from "../../components/header/header";
// import Header from "../../components/header/header";
import Header from "../../components/header/header";
import "./index.css";
import { Loader } from "../../components/loader/loader";

const {
  blockChainService,
  clonePolygon,
  copyPolygon,
  createFiles,
  dockerFolderRun,
  dockerContainerRun,
} = config.authEndPoints;
const { dockerRunFile } = config.authEndPoints;

const inputData = [
  {
    label: "Network Name:",
    name: "networkName",
    placeholder: "Please enter your network name",
  },
  {
    label: "Network Symbol:",
    name: "networkSymbol",
    placeholder: "Please enter your network symbol",
  },
  {
    label: "Wallet Address",
    name: "genesis_alloc_address",
    placeholder: "Wallet Address",
  },
  {
    label: "Network ID:",
    name: "sh_network_id",
    placeholder: "Network Id",
  },
  {
    label: "Native Currency Supply:",
    name: "genesis_alloc_balance",
    placeholder: "Native Currency Supply",
  },
  {
    label: "HTTP Port:",
    name: "sh_http_port",
    placeholder: "HTTP Port",
  },
  {
    label: "WS Port:",
    name: "sh_ws_port",
    placeholder: "WS Port",
  },
  {
    label: "JSON Rpc Port:",
    name: "dockerfile_expose_port",
    placeholder: "Authrpc Port",
  },
];
const validationSchema = Yup.object().shape({
  networkName: Yup.string().required("This is required field"),
  networkSymbol: Yup.string().required("This is required field"),
  genesis_alloc_address: Yup.string().required("Wallet Address is required"),
  sh_network_id: Yup.string().required("Network ID is required"),
  genesis_alloc_balance: Yup.string().required(
    "Native Currency Supply is required"
  ),
  sh_http_port: Yup.string().required("HTTP Port is required"),
  sh_ws_port: Yup.string().required("WS Port is required"),
  dockerfile_expose_port: Yup.string().required("JSON Rpc Port is required"),
  blockchain: Yup.string().required("Blockchain is required"),
  consensus: Yup.string().required("Consensus is required"),
  additionalDetails: Yup.string(),
});

const initialValues = {
  networkName: "",
  networkSymbol: "",
  genesis_alloc_address: "",
  sh_network_id: "",
  genesis_alloc_balance: "",
  sh_http_port: "",
  sh_ws_port: "",
  dockerfile_expose_port: "",
  blockchain: "",
  consensus: "",
  additionalDetails: "",
};

const RollupForm = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const handleSubmit = async (values) => {
    const data = {
      networkName: values.networkName,
      networkSymbol: values.networkSymbol,
      genesis_chainId: values.sh_network_id,
      genesis_alloc_address: values.genesis_alloc_address,
      sh_network_id: values.sh_network_id,
      genesis_alloc_balance: values.genesis_alloc_balance,
      sh_http_port: values.sh_http_port,
      sh_ws_port: values.sh_ws_port,
      dockerfile_expose_port: values.dockerfile_expose_port,
    };
    try {
      const response = await get(clonePolygon, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      if (response?.responseData?.message !== "Folder already exists") {
        const response = await get(copyPolygon, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        });
        if (response?.responseData?.message !== "Folder already exists") {
          const response = await post(
            createFiles,
            {
              networkName: values.networkName,
              jsonRpcPort: values.dockerfile_expose_port,
              rootAddress:
                "0xe374b91f8835801b74b3cbada8e238eb30ee9cc34a8286948907c0b0c40786c0",
            },
            {
              headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
              },
            }
          );
          if (response) {
            const response = await get(dockerFolderRun, {
              headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
              },
            });
            if (response) {
              setLoader(true);
              setTimeout(async () => {
                const response = await get(dockerContainerRun, {
                  headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                  },
                });
                setLoader(false);
                toast.success(response?.responseData?.message);
              }, 60000);
            }
          }
        }
      }
    } catch (error) {}
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
    <Header/>
  
      {loader ? (
        <Loader />
      ) : (
        <div className="blockchain_development_main">
          <h3>Launch Your RAAS Client</h3>
          <div className="blockchain_development_main_form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <Grid container spacing={2}>
                  {inputData.map((data, i) => (
                    <Grid item xs={12} sm={6} key={i}>
                      <div className="blockchain_development_label_form_box">
                        <label>{data.label}</label>
                        <Field
                          type="text"
                          name={data.name}
                          placeholder={data.placeholder}
                        />
                        <ErrorMessage
                          name={data.name}
                          component="div"
                          style={{ color: "red" }}
                        />
                      </div>
                    </Grid>
                  ))}

                  <Grid item xs={12} sm={6}>
                    <div className="blockchain_development_label_form_box">
                      <label>{"Blockchain:"}</label>
                      <Field as="select" name="blockchain">
                        <option value="">Select Option</option>
                        <option value="Geth (local client)">
                          Geth (Local Client)
                        </option>
                        {/* <option value="BSC">BSC</option> */}
                      </Field>
                      <ErrorMessage
                        name="blockchain"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div className="blockchain_development_label_form_box">
                      <label>{"Consensus:"}</label>
                      <Field as="select" name="consensus">
                        <option value="">Select Option</option>
                        <option value="IBFT">IBFT</option>
                        <option value="POLYBFT">POLYBFT</option>
                      </Field>
                      <ErrorMessage
                        name="consensus"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="blockchain_development_label_form_box">
                      <label>{"Additional Details:"}</label>
                      <Field
                        as="textarea"
                        name="additionalDetails"
                        style={{ height: 112 }}
                      />
                      <ErrorMessage
                        name="additionalDetails"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "center", marginTop: 40 }}
                  >
                    <button
                      type="submit"
                      className="blockchain_development_button"
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default RollupForm;
