import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./css/environment.css";
import Header from "../../components/Header copy/NewHeader";

const Deployed = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="environments_main">
        <div>
          <h3>Node has been deployed</h3>
          <p className="white_color">
            {"Check your mail for further assistance"}
          </p>
        </div>

        <div className="environments_main_form">
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ textAlign: "center", marginTop: 40 }}>
              <button
                onClick={() => navigate("/")}
                type="submit"
                className="setup_node_button"
              >
                Home
              </button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Deployed;
