import React, { useState } from "react";
import { Grid } from "@mui/material";
import Radio from "@mui/material/Radio/Radio";
import "./css/provider.css";
const data = [
  {
    title: "Geth",
    statement:
      "Open source Enterprise Ethereum with privacy extensions, built on Go Ethereum ",
  },
  {
    title: "Quoram",
    statement:
      "Open source Enterprise Ethereum with privacy extensions, built on Go Ethereum ",
  },
  {
    title: "Hyperledger Besu",
    statement:
      "Open source Enterprise Ethereum with privacy extensions, built on Go Ethereum ",
  },
];
const secondData = [
  {
    title: "POA",
  },
  {
    title: "Raft",
  },
  {
    title: "IBFT",
  },
];

const NetworkDetails = ({ setTab }) => {
  const [provider, setProvider] = useState({
    title: "Geth",
    statement:
      "Open source Enterprise Ethereum with privacy extensions, built on Go Ethereum ",
  });
  const [algo, setAlgo] = useState({
    title: "POA",
  });
  const [setting, setSetting] = useState("");
  const handleSubmit = () => {
    if (provider && algo && provider) setTab("Configure");
  };
  return (
    <div className="provider_main">
      <div>
        <h3>Select Provider Settings - Dev</h3>
        <p>Choose your provider and associated alogrithm</p>
      </div>

      <div className="provider_main_form">
        <div className="provider_label_form_box">
          <label>{"Provider"}</label>
          <Grid container spacing={2}>
            {data.map((item, i) => (
              <Grid item xs={12} md={4} key={i}>
                <div
                  className={
                    i == 0
                      ? "provider_card active_border"
                      : "provider_card diable_card"
                  }
                  onClick={() => {
                    i == 0 && setProvider(item);
                  }}
                >
                  <div className="radio_div">
                    <Radio
                      name="region"
                      checked={provider.title == item.title}
                    />
                  </div>
                  <div className="provider_content_div">
                    <p className="text_Align_left card_heading">{item.title}</p>
                    <p className="text_Align_left">{item.statement}</p>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      {/* ---------------------------------------------- */}

      <div>
        <h3>Conensus Algorithm</h3>
      </div>
      <div className="provider_main_form">
        <div className="provider_label_form_box">
          <Grid container spacing={2}>
            {secondData.map((item, i) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={i}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div
                  className="provider_radio_div"
                  onClick={() => {
                    i == 0 && setAlgo(item);
                  }}
                >
                  <Radio
                    name="region"
                    checked={algo.title == item.title}
                    style={{ color: "#A74505" }}
                  />
                  <p className="text_Align_left card_heading">{item.title}</p>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
      {/* ----------------------------------------------- */}
      <div>
        <h3>Advanced Settings (Optional)</h3>
        <p className="white_color">
          {
            "These options cannot be changed after the environment is created . Learn More"
          }
        </p>
      </div>

      <div className="provider_main_form">
        <div className="provider_label_form_box">
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <select onChange={(e) => setSetting(e.target.value)}>
                <option value="">Select Option</option>
                <option value="POW">POW</option>
              </select>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={6}
              style={{ textAlign: "center", marginTop: 40 }}
            >
              <button
                onClick={() => setTab("Provider")}
                className="setup_node_button border_none"
              >
                Cancel
              </button>
            </Grid> */}
            {/* <Grid
              item
              xs={12}
              sm={6}
              style={{ textAlign: "center", marginTop: 40 }}
            >
              <button
                onClick={handleSubmit}
                type="submit"
                className="setup_node_button"
              >
                Next
              </button>
            </Grid> */}
            <div className="flex-center-box">
              <button
                className="network_details_button"
                onClick={() => setTab("Provider")}
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                className="network_details_button"
              >
                Next
              </button>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default NetworkDetails;
