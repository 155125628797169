import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header2 from "../../components/header/header";

const NetworkDetails = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Header2 />
      <div className="configure_main">
        <div>
          <h3>Create an Environment</h3>
          <p className="white_color">
            {
              "Let’s create some blockchain resources and bring your networ to life."
            }
          </p>
        </div>
        {/* <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ textAlign: "center", marginTop: 40 }}
          >
            <button className="setup_node_button border_none">
              Documentation
            </button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ textAlign: "center", marginTop: 40 }}
          >
            <button
              onClick={() => navigate("/blockchain-development")}
              className="setup_node_button width_100"
            >
              Create Environment
            </button>
          </Grid>
        </Grid> */}

        <div className="flex-center-box">
          <button className="network_details_button">Documentation</button>

          <button
            type="submit"
            className="network_details_button"
            onClick={() => navigate("/blockchain-development")}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default NetworkDetails;
