import { Footer } from "../../components/footer/footer";
import Header from "../../components/Header copy/NewHeader";
import { config } from "../../config";
import { post } from "../../service/apiService";
import toast from "react-hot-toast";
import "../../index.css";
import { useState } from "react";
// import "../../app.css";
const { contact } = config.authEndPoints;
export const ContactUs = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone_Number: "",
    message: "",
    contry_code: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = async () => {
    try {
      const response = await post(contact, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response?.success) {
        toast.success("Message Send Successfully");
        setFormData({
          full_name: "",
          email: "",
          phone_Number: "",
          message: "",
          contry_code: "",
        });
      } else {
        toast.error(response?.message);
      }
    } catch (error) {}
  };
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div id="__next">
      <Header click={scrollToSection} />
      <section id="general_banner_main">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="bread_crumbs">
                <h1>Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div id="rt_contact">
        <div className="container">
          <div className="row">
            <div className="mt_heading" style={{ width: "100%" }}>
              <h2 className="section_heading text-center">
                <span className="faded_text">Contact</span>
                <span className="heading_text">Contact Us</span>
              </h2>
              <p className="heading_txt text-center">
                For any inquiries or collaboration opportunities, please feel
                free to reach out to us via the contact form below.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <div className="contact_form" style={{ background: "#fff" }}>
                <div id="contact_form">
                  <div className="row">
                    <div className="col-sm-6">
                      <input
                        type="text"
                        name="full_name"
                        id="name"
                        placeholder="Your name"
                        required=""
                        value={formData.full_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-sm-6">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Your email"
                        required=""
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-sm-12">
                      <textarea
                        cols="30"
                        rows="5"
                        name="message"
                        id="message"
                        placeholder="Your message"
                        required=""
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      <button
                        className="rt_btn_color"
                        id="submit-btn"
                        onClick={handleSubmit}
                      >
                        Send
                        <span className="mt_load">
                          <span></span>
                        </span>
                      </button>
                      <div id="msg"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};
