import React, { useState } from "react";
import NetworkDetails from "./networkDetails";
import SetUpNode from "./setUpNode";
import DeployRegions from "./deployRegions";
import Environments from "./environmentType"
import Protocol from "./protocol";
import Provider from "./provider";
import Configure from "./configure";
import Create from "./create";

const RollupForm = () => {
    const [tab, setTab] = useState("NetworkDetails");
    return (
        <div>
            {tab === "NetworkDetails" && <NetworkDetails setTab={setTab} />}
            {tab === "SetUpNode" && <SetUpNode setTab={setTab} />}
            {tab === "DeployRegions" && <DeployRegions setTab={setTab} />}
            {tab === "EnvironmentType" && <Environments setTab={setTab} />}
            {tab === "Protocol" && <Protocol setTab={setTab} />}
            {tab === "Provider" && <Provider setTab={setTab} />}
            {tab === "Configure" && <Configure setTab={setTab} />}
            {tab === "create" && <Create />}
        </div>
    );
};

export default RollupForm;
