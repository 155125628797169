import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./caraousel.css";
import "./Index.css";
export const CaseStudy = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div
      id="rt_welcome"
      style={{
        paddingTop: "0px",
      }}
    >
      <div className="section_heading_about text-center">
        <br />
        <h3 className="section_heading text-center">Case Studies</h3>
        <p className="heading_txt text-center">
          Doras caters to a wide range of use cases across various sectors using
          blockchain technology due to <br />
          its decentralized, secure, and transparent nature.
        </p>
      </div>
      <div className="horizontal-card-grid">
        <Slider {...settings}>
          <div>
            <div className="rt_service_box">
              <i className="icon-strategy"></i>
              <h4>Enterprise-Grade Security</h4>
              <p>
                Ready-made solutions and supportservices to reduce the costs
                associated with blockchain implementation, makingit more
                feasible for businesses of all sizes.
              </p>
            </div>
          </div>

          <div>
            <div className="rt_service_box">
              <i className="icon-wallet"></i>
              <h4>Deployment Options</h4>
              <p>
                Ready-made solutions and supportservices to reduce the costs
                associated with blockchain implementation, makingit more
                feasible for businesses of all sizes.
              </p>
            </div>
          </div>

          <div>
            <div className="rt_service_box">
              <i className="icon-presentation"></i>
              <h4>High Performance</h4>
              <p>
                Ready-made solutions and supportservices to reduce the costs
                associated with blockchain implementation, makingit more
                feasible for businesses of all sizes.
              </p>
            </div>
          </div>

          <div>
            <div className="rt_service_box">
              <i className="icon-briefcase"></i>
              <h4>Real-Time Data Analytics</h4>
              <p>
                Ready-made solutions and supportservices to reduce the costs
                associated with blockchain implementation, makingit more
                feasible for businesses of all sizes.
              </p>
            </div>
          </div>
          <div>
            <div className="rt_service_box">
              <i className="icon-strategy"></i>
              <h4>24/7 Resource Monitoring</h4>
              <p>
                Ready-made solutions and supportservices to reduce the costs
                associated with blockchain implementation, makingit more
                feasible for businesses of all sizes.
              </p>
            </div>
          </div>
        </Slider>
        
      </div>
      {/* <div style={{display: "flex" , justifyContent: "center" , marginTop: "70px"}}>
          <Link className="gradient_btn" to="/">
            <span>Book An Appointment</span>{" "}
          </Link>
        </div> */}
    </div>
  );
};
