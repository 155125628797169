import axios from "axios";
import { Alert } from "@mui/material";
const API_BASE_URL = "https://doras-mvp-backend.bitviraj.com";

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

const get = async (url, config = {}) => {
  try {
    console.log(config , "Conf")
    const response = await apiService.get(url, config , {});
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const post = async (url, data = {}, config = {}) => {
  try {
    const response = await apiService.post(url, data, config);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const put = async (url, data = {}, config = {}) => {
  try {
    const response = await apiService.put(url, data, config);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const remove = async (url, config = {}) => {
  try {
    const response = await apiService.delete(url, config);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Export the functions to use them in your components
export { get, post, put, remove };
