import "../../index.css";
export const Loader = () => {
  return (
    <div
      style={{
        opacity: "1",
        zIndex: "999",
        backgroundColor: "white",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        left: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "opacity 0.2s ease",
      }}
    >
      <div className="loading">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};
